import { graphql, useStaticQuery } from "gatsby"
import ImgComponent from "../imgComponent"

const ContentCA = () => {
  const downloadFile = useStaticQuery(graphql`
    query QueryDownloadCA {
      file(relativePath: { eq: "CA-Service.xlsx" }) {
        publicURL
        name
      }
    }
  `)
  return (
    <section
      className="elementor-element elementor-element-5ac5a52 elementor-section-stretched elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle elementor-section elementor-top-section"
      data-id="5ac5a52"
      data-element_type="section"
      data-settings='{"stretch_section":"section-stretched","background_background":"classic","shape_divider_bottom":"tilt"}'
    >
      <div
        className="elementor-shape elementor-shape-bottom"
        data-negative="true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            className="elementor-shape-fill"
            d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"
          />
        </svg>
      </div>
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-element elementor-element-10e9a05 elementor-column elementor-col-33 elementor-top-column elementor-text-CA width-konten-service-CA"
            data-id="10e9a05"
            data-element_type="column"
            data-settings='{"":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-c3f3cb1 elementor-widget__width-auto elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading"
                  data-id="c3f3cb1"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1,"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h1
                      className="elementor-heading-title elementor-size-default  title-size"
                      id="padding-title-main"
                    >
                      Contract Analysis
                    </h1>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-d455920 elementor-widget elementor-widget-text-editor"
                  data-id="d455920"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1,"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-text-editor elementor-clearfix">
                      <div className="lqd-lines split-unit lqd-unit-animation-done fontsize-service-ca">
                        <p>
                          For customers who wants to outsource their analytical
                          works, i.e. vitamin, antibiotics, etc in
                          pharmaceutical dosage forms or other formats, we will
                          be glad to assist you. We ensure that you will get
                          your analytical result in a timely manner thanks to
                          our in-house labs and our collaboration with several
                          labs around Jakarta.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-c3f3cb1 elementor-widget__width-auto elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading"
                  data-id="c3f3cb1"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1,"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container fontsize-service-ca padding-bawah-konten-serv-ca">
                    <h5
                      className="elementor-heading-title elementor-size-default"
                      style={{ color: "#000000" }}
                    >
                      <br />
                      Our services include :
                    </h5>
                    <br />
                    1. Chemical Testing
                    <br />
                    2. Microbiology Testing
                    <br />
                    3. Biomolecular Testing
                    <br />
                    4. Physical/Packaging Testing
                    <br />
                    5. Halal Testing
                    {/* <p>To find out more about our Clinical Trial services,</p>
                    <a
                      className="button"
                      id="warnaButton"
                      // href={`CA-Service.xlsx`}
                      // href={downloadFile.file.publicURL}
                      href="https://drive.google.com/file/d/1_p-aqalclaSjNNbePQyon3fWlCSss7_L/view?usp=sharing"
                      target="_blank"
                      // download
                    >
                      Click Here{" "}
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-bd28f10 elementor-column elementor-col-66 elementor-top-column elementor-CA width-image-service-CA"
            data-id="bd28f10"
            data-element_type="column"
            data-settings='{"":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-eded2fd animation mobile_static elementor-widget__width-initial elementor-absolute elementor-widget elementor-widget--image "
                  data-id="eded2fd"
                  data-element_type="widget"
                  data-settings='{"_position":"absolute","":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="image.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-image image-margin-service-CA">
                      {/* <img
												width={1440}
												height={956}
												src={img1}
												className="attachment-full size-full"
												alt=""
												sizes="(max-width: 1440px) 100vw, 1440px"
											/> */}

                      <ImgComponent
                        pmlImg="experience_BM02.jpg"
                        className="image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContentCA
